<template>
    <div class="resume-edit container my-2">
        
        <form>
            <div class="d-flex justify-content-center img">
                  <img id="avatar" alt="logo">
                  <div  class="file img" id="res">
                    <input ref="file" type="file" name="resumeavatar" id='upimg' accept="image/*" @change="fileHandler"/>  
                  </div>
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">名 称<i class="rqd">*</i></label>
                <input type="text" class="form-control w-100 rounded" name="name" placeholder="请输入企业名称" required v-model.trim.lazy="data.name" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >性 质<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.nature">
                    <option v-for="(items,index) in NATURE" :key="index" :value="index"> {{ items }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >类 型<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.type">
                    <option v-for="(items,index) in COMPANY_TYPE" :key="index" :value="index"> {{ items }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >规 模</span>
                <input type="number" class="form-control w-100 rounded" name="name" placeholder="请输入企业规模人数" v-model.trim.lazy="data.scale" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >福 利<i class="rqd"></i></span>
                <div class="w-100" @click="showWelare">
                    <select class="form-select p-2 w-100 rounded" multiple="multiple" v-model="data.welfare">
                        <option v-for="(name, code) in WELFARE" :key="code" :value="code">{{ name }}</option>
                    </select>
                </div>
            <!--                 
                <div class="bg-white w-100 h-15 border welare p-2 px-3" v-if="isShow">
                    <div v-for="(value, code) in WELFARE" :key="code" class="py-1">
                        <input type="checkbox" :id="'welare' + code" :value="code" @change="getWelare(code)"><label :for="'welare' + code">{{ value }}</label>
                    </div>
                    
                </div> -->
            </div>

            <div class="input-group my-1 has-feedback">
                <span class="m-1" >坐 标</span>
                <input type="text" class="form-control w-100 rounded" name="name" placeholder="请输入企业坐标" v-model.trim.lazy="data.map" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >地 址</span>
                <input type="text" class="form-control w-100 rounded" name="name" placeholder="请输入企业地址" v-model.trim.lazy="data.address" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >联系人</span>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入联系人姓名" v-model.trim.lazy="data.contact" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >电 话</span>
                <input type="number" class="form-control w-100 rounded" placeholder="请输联系人电话" v-model.trim.lazy="data.contact_tel">
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="" for="inputGroupSelect01">简 介</label>
                
                <textarea class="form-control w-100" rows="3" v-model.lazy="data.description" ></textarea>
            </div>

            <upimgs v-if="showImage" @changed="saveImage" ></upimgs>
           

            <input type="text" style="display:none" :value="data.id">
            <button class="btn btn-success btn-lg w-100 mt-5" @click.prevent="submit">提交</button>
        </form>
    </div>
    <!-- 提示框 -->
    <Hint v-if="isOk" :msg="msg" @btnok="isOk=false"></Hint>
    <Cropping v-if="showEditimg" :picture="image" @close="close" @finish="save"></Cropping>

</template>

<script>


import { watch, ref, onMounted } from 'vue'
import axios from 'axios'
import { COMPANY_TYPE, NATURE, WELFARE} from '@/lib/data'
import { getUrlCode, getImageFileFromUrl } from '@/lib/unitls'
import Cropping from '@/components/lib/Cropping.vue'
import upimgs from "@/components/lib/upimgs.vue"

export default {
    name:'addCompany',
    components:{
        Cropping,
        upimgs
    },
    
    setup () {    
        let file = ref()
        const isShow = ref(false)

        const isOk = ref(false)
        const btnStatus = ref(false)
        const msg = { type: 'success', message: '提交成功'}
        const errormsg = ref('请认真输入完整的信息，否则会无法提现')
        const showEditimg = ref(false)
        const image = ref()
        
        const images = ref()
        const showImage = ref(false)
        const files = ref([])

        let data = ref({
            name: '', //企业名称 Strying 必填
            logo: 0, //企业Logo  number
            images: [], //企业展示图片 Array
            map: '', //sps 坐标 Strying
            scale: 0, //企业规模  number
            address:"", //企业地址 Strying
            description:"", //企业 简介 Strying
            contact:"", //企业联系人 Strying 
            contact_tel: "", //联系人电话 Strying
            nature: 0, //企业性质 Number 
            type: 0, //行业类型 Number
            welfare:[] //福利待遇 Array
        })

        
        function getInfo(id) {
            axios.get('/factory.json?id=' + id).then((response) =>{
                data.value = response.data.data
                if (data.value.images && data.value.images.length > 0) {
                    Promise.all(data.value.images.map(url => getImageFileFromUrl(url, url))).then(data => {
                        files.value = data
                        showImage.value = true
                    })
                }
            })
        }
        onMounted(() =>{
            getInfo(getUrlCode())

        })

        function showWelare() {
            isShow.value = !isShow.value
        }

        function fileHandler () {
            image.value = file.value.files[0]
            showEditimg.value = true
            // console.log("file: ", file.value.files[0])
        }
//提交
        function submit() {
            if ( 30 < data.value.name.length || data.value.name.length < 2 ) {
                btnStatus.value = false
                errormsg.value = '公司名不能小于2个字或大于30个字'
                return
            }
            axios.post('http://localhost:5000/companys', data.value).then((response) => {
                console.log(response)
            })
        }

        /* 获取多个图片文件 */
        function saveImage (files) {
            images.value = files
        }

        /**获取 logo图片 */
        function save (data) {
            const el = document.querySelector('#avatar')

            if (el) {
                const url = URL.createObjectURL(data)
                data.value.logo = url
                el.setAttribute('src', url)
            }

            showEditimg.value = false
        }

        function close() {
            showEditimg.value = false
        }

        watch(() => data.value.name, (newValue) => {
            if (newValue.length < 2 || newValue.length > 30) {
                errormsg.value = '企业不能小于2个字或大于30个字'
                alert(errormsg.value)
                btnStatus.value = false
                return
            }
            btnStatus.value = true
            return
        })


        

        return {
            WELFARE,
            file,
            close,
            NATURE,
            COMPANY_TYPE,
            data,
            btnStatus,
            msg,
            showEditimg,
            errormsg,
            fileHandler,
            submit,
            isOk,
            save,
            image,
            images,
            saveImage,
            isShow,
            showWelare
        }
    }
}
</script>

<style scoped>
.welare {
    height: auto;
    position: absolute;
    z-index: 1000;
    top:4.5rem;
}
.welare-btn {
    z-index: 100000;
}
.resume-edit .input-group-text {
    border-radius: 0.25rem 0 0 0.25rem;
}

.img {
    text-align: center;
    margin-bottom:5px;
}

.img img {
    width: 60px;
    height: 70px;
}

.file {
    position: absolute;
    top:10px;
    width:60px;
    height: 70px;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
}

.file input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.rqd, .errMsg {
    color:red;
}

form select {
    flex: auto;
    border-radius: 0;
    background: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}

form textarea {
    width: 100%;
    min-height: 80px;
    background-color: #ffffff;
    border-color: #ffffff;
}

form .input-group-text, form .form-control {
    background-color: #ffffff;
    border-color: #ffffff;
}

</style>
